export {}

const languageSelects = document.querySelectorAll(".c-language-select-mobile")

languageSelects.forEach((select) => {
  const header = select.querySelector(".c-language-select-mobile__active")

  header?.addEventListener("click", () => {
    select.classList.toggle("--open")
  })
})
